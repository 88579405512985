<template>
    <v-container fluid>
      <v-row>
        <v-col class="col-12">
          <h1>
            Complete jobs
            <v-btn
                @click="viewApplications = true"
                class="ml-4"
                small
                v-if="!viewApplications">
              View Applications
            </v-btn>
            <v-btn
                @click="viewApplications = false"
                class="ml-4"
                small
                v-if="viewApplications">
              View Complete Jobs
            </v-btn>
            <v-btn
                @click="viewRemedials = true"
                class="ml-4"
                small
                v-if="!viewRemedials">
              View Remedials
            </v-btn>
            <v-btn
                @click="viewRemedials = false"
                class="ml-4"
                small
                v-if="viewRemedials">
              View Complete Jobs
            </v-btn>
            <span class="float-right">
              P/L last calculated on: {{ lastCalcDate | prettyDate }}
            </span>
          </h1>
          <v-form @submit.prevent="getQuotations">
            <v-row style="width:100%" class="pt-8">
              <v-col class="col">
                <label>Job No</label>
                <v-text-field v-model="jobNumber"></v-text-field>
              </v-col>
              <v-col class="col">
                <label>Customer</label>
                <v-text-field v-model="customer"></v-text-field>
              </v-col>
              <v-col class="col">
                <label>Sales person</label>
                <v-text-field v-model="salesPerson"></v-text-field>
              </v-col>
              <v-col class="col">
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="date"
                  persistent
                  width="350px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <label class="pl-8">Date</label>
                    <v-text-field
                      v-model="date"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    range>
                    <v-spacer></v-spacer>
                    <v-btn
                      text
                      color="primary"
                      @click="modal = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(date)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col class="col">
                <label>% Profit</label>
                <v-select :items="percentages" v-model="percentage"></v-select>
              </v-col>
              <v-col class="pt-10">
                <v-btn type="sumbit">Search</v-btn>
                &nbsp;
                <v-btn @click="clearSearch">Clear</v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table
            fixed-header
            height="450px"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">No</th>
                  <th class="text-left">Customer</th>
                  <th class="text-left">Address</th>
                  <th class="text-left">Date</th>
                  <th class="text-left">Value</th>
                  <th class="text-left">Profit</th>
                  <th class="text-left">% Margin</th>
                  <th class="text-left">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="quotation in quotations"
                  :key="quotation.id"
                >
                  <td>{{ quotation.jobNumber }}</td>
                  <td>{{ quotation.customer }}</td>
                  <td>{{ quotation.site  }}</td>
                  <td>{{ quotation.date | tinyDate }}</td>
                  <td>{{ quotation.value | priceInPounds | currency }}</td>
                  <td>{{ quotation.profit | priceInPounds | currency }}</td>
                  <td>
                    <span v-if="quotation.profitPercentage <= 0" class="text-red text-heavy">
                      {{ quotation.profitPercentage | priceInPounds }}
                    </span>
                    <span v-if="quotation.profitPercentage > 0 && quotation.profitPercentage <= 3000" class="text-amber text-heavy">
                      {{ quotation.profitPercentage | priceInPounds }}
                    </span>
                    <span v-if="quotation.profitPercentage > 3000" class="text-green text-heavy">
                      {{ quotation.profitPercentage | priceInPounds }}
                    </span>
                  </td>
                  <td class="pr-0">
                    <router-link custom v-slot="{ navigate }" :to="`/reports/job-pl/pl/${quotation.id}`">
                      <v-btn
                        title="Costs"
                        x-small
                        @click="navigate"
                        @keypress.enter="navigate"
                        role="link">
                          P/L
                      </v-btn>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-pagination
            class="pt-4"
            v-model="page"
            :length="length"
            prev-icon="mdi-menu-left"
            next-icon="mdi-menu-right"
            :total-visible="totalVisible"
          ></v-pagination>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="exportCosts">Export</v-btn>
        </v-col>
      </v-row>
      <router-view></router-view>

      <br /><br /><br /><br /><br /><br /><br /><br />
      <v-row>
        <v-col class="col-1">
          <v-text-field v-model.number="pageCalc" type="number"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-1">
          <v-btn x-small @click="calculateProfits()">Go</v-btn>
          <v-btn x-small @click="calculateRemedials()">Go R</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </template>

  <script>
  import axios from '../../../axios';

  export default {
    name: 'JobCostsIndex',
    computed: {
      lastCalcDate() {
        let d = new Date();
        d.setDate( d.getDate() + 6 - d.getDay() -7 );
        return d;
      },
      token() {
        return this.$store.state.token;
      },
    },
    data() {
      return {
        customer: '',
        date: [],
        dateDialogue: false,
        jobNumber: '',
        length: 0,
        modal: false,
        page: 1,
        percentage: 0,
        percentages: [
          { value: 0, text: 'All' },
          { value: 1, text: '<= 0%' },
          { value: 2, text: '> 0% < 30%' },
          { value: 3, text: '>= 30%' },
        ],
        quotations: [],
        salesPerson: '',
        totalVisible: 9,
        pageCalc: 0,
        viewApplications: false,
        viewRemedials: false,
      };
    },
    watch: {
      page() {
        this.getQuotations();
      },
      viewApplications() {
        if (this.page > 1) {
          this.page = 1;
        } else {
          this.getQuotations();
        }
      },
      viewRemedials() {
        if (this.page > 1) {
          this.page = 1;
        } else {
          this.getQuotations();
        }
      },
    },
    methods: {
      calculateProfits() {
        const page = this.pageCalc;
        axios.get(`/quotations/calculateProfits/${page}.json?token=${this.token}`);
      },
      calculateRemedials() {
        const page = this.pageCalc;
        axios.get(`/quotations/calculateProfits/${page}/true.json?token=${this.token}`);
      },
      getQuotations() {
        const postData = {
          jobNumber: this.jobNumber,
          customer: this.customer,
          date: this.date,
          hasCost: 1,
          percentage: this.percentage,
          salesPerson: this.salesPerson,
          invoiced: 1,
          viewApplications: this.viewApplications,
          viewRemedials: this.viewRemedials,
        };
        axios.post(`/invoices/getCostIndex/${this.page}.json?token=${this.token}`, postData)
          .then((response) => {
            this.quotations = response.data.costs;
            this.length = response.data.length;
          })
          .catch((error) => {
            console.log(error);
          });
      },
      exportCosts() {
        const postData = {
          jobNumber: this.jobNumber,
          customer: this.customer,
          date: this.date,
          hasCost: 1,
          percentage: this.percentage,
          salesPerson: this.salesPerson,
          invoiced: 1,
          viewApplications: this.viewApplications,
          viewRemedials: this.viewRemedials,
        };
        axios.post(`/spreadsheets/exportCosts/${this.page}.json?token=${this.token}`, postData, {responseType:'blob'})
          .then((response) => {
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `Job costs.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
            })
          .catch((error) => {
            console.log(error);
          });
      },
      clearSearch() {
        this.jobNumber = '';
        this.customer = '';
        this.salesPerson = '';
        this.date = [];
        this.getQuotations();
      },
    },
    mounted() {
      this.getQuotations();
    },
  };
  </script>
